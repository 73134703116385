import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  extendTheme,
  Box,
  VStack,
  Heading,
  Text,
  Flex,
  Container,
  SimpleGrid,
  AspectRatio,
  Icon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Tbody,
  Tr,
  Td,
  useDisclosure,
  useBreakpointValue,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { FaIndustry, FaCog, FaTools, FaFlag, FaInfoCircle, FaCheckCircle, FaMapMarkerAlt, FaPhone, FaClock } from 'react-icons/fa';

// Update the theme with a more refined color palette
const theme = extendTheme({
  colors: {
    brand: {
      50: '#fffbea',
      100: '#fff3c4',
      200: '#fce588',
      300: '#fadb5f',
      400: '#f7c948',
      500: '#f0b429', // Main yellow
      600: '#de911d',
      700: '#cb6e17',
      800: '#b44d12',
      900: '#8d2b0b',
    },
  },
  fonts: {
    heading: '"Montserrat", sans-serif',
    body: '"Open Sans", sans-serif',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'semibold',
      },
      variants: {
        solid: {
          bg: 'brand.500',
          color: 'white',
          _hover: { bg: 'brand.600' },
        },
        outline: {
          borderColor: 'brand.500',
          color: 'brand.500',
          _hover: { bg: 'brand.50' },
        },
      },
    },
  },
});

const TechnicalDetailsModal = ({ isOpen, onClose, productName, technicalDetails }) => {
  const tableData = Object.entries(technicalDetails).filter(([key]) => key !== 'Additional Features');
  const additionalFeatures = technicalDetails['Additional Features'] || [];

  const modalSize = useBreakpointValue({ base: "full", md: "5xl" });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize} motionPreset="slideInBottom">
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent borderRadius="lg" overflow="hidden">
        <ModalHeader bg="brand.500" color="white" py={6}>
          <Flex align="center">
            <Icon as={FaInfoCircle} mr={3} />
            <Text>{productName} - Technical Details</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody bg="gray.50" p={0}>
          <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }} gap={0}>
            <GridItem p={8} bg="white">
              <Heading size="md" mb={4} color="brand.600">Technical Parameters</Heading>
              <Table variant="simple" size="sm">
                <Tbody>
                  {tableData.map(([key, value]) => (
                    <Tr key={key}>
                      <Td fontWeight="medium" color="gray.700" width="50%">{key}</Td>
                      <Td color="gray.600">{value}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </GridItem>
            <GridItem p={8} bg="gray.50">
              <Heading size="md" mb={4} color="brand.600">Additional Features</Heading>
              <VStack align="stretch" spacing={3}>
                {additionalFeatures.map((feature, index) => (
                  <Flex key={index} align="center">
                    <Icon as={FaCheckCircle} color="brand.500" mr={3} />
                    <Text fontSize="sm" color="gray.700">{feature}</Text>
                  </Flex>
                ))}
              </VStack>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter bg="gray.100">
          <Button colorScheme="brand" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const ProductSection = ({ name, description, videoUrl, isReversed, icon, technicalDetails }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mb={20}>
        {isReversed ? (
          <>
            <Box order={{ base: 2, md: 1 }}>
              <Flex align="center" mb={6} display={{ base: 'none', md: 'flex' }}>
                <Icon as={icon} boxSize={10} color="brand.500" mr={4} />
                <Heading as="h3" size="xl">{name}</Heading>
              </Flex>
              <Text fontSize="lg" mb={6} color="gray.700">{description}</Text>
              <Flex justify="center">
                <Button onClick={onOpen} leftIcon={<FaInfoCircle />} variant="outline" size="lg">
                  Technical Details
                </Button>
              </Flex>
            </Box>
            <Box order={{ base: 1, md: 2 }}>
              <Flex align="center" mb={4} display={{ base: 'flex', md: 'none' }}>
                <Icon as={icon} boxSize={8} color="brand.500" mr={3} />
                <Heading as="h3" size="lg">{name}</Heading>
              </Flex>
              <AspectRatio ratio={16 / 9} boxShadow="xl" borderRadius="lg" overflow="hidden">
                <iframe
                  src={videoUrl}
                  title={name}
                  allowFullScreen
                />
              </AspectRatio>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Flex align="center" mb={4} display={{ base: 'flex', md: 'none' }}>
                <Icon as={icon} boxSize={8} color="brand.500" mr={3} />
                <Heading as="h3" size="lg">{name}</Heading>
              </Flex>
              <AspectRatio ratio={16 / 9} boxShadow="xl" borderRadius="lg" overflow="hidden">
                <iframe
                  src={videoUrl}
                  title={name}
                  allowFullScreen
                />
              </AspectRatio>
            </Box>
            <Box>
              <Flex align="center" mb={6} display={{ base: 'none', md: 'flex' }}>
                <Icon as={icon} boxSize={10} color="brand.500" mr={4} />
                <Heading as="h3" size="xl">{name}</Heading>
              </Flex>
              <Text fontSize="lg" mb={6} color="gray.700">{description}</Text>
              <Flex justify="center">
                <Button onClick={onOpen} leftIcon={<FaInfoCircle />} variant="outline" size="lg">
                  Technical Details
                </Button>
              </Flex>
            </Box>
          </>
        )}
      </SimpleGrid>
      <TechnicalDetailsModal
        isOpen={isOpen}
        onClose={onClose}
        productName={name}
        technicalDetails={technicalDetails}
      />
    </>
  );
};

const ContactSection = () => {
  const contactDetails = [
    {
      icon: FaMapMarkerAlt,
      title: "Address",
      content: "W/10, near Durga Katta, M.I.D.C, Jalna, Maharashtra 431203"
    },
    {
      icon: FaPhone,
      title: "Phone",
      content: ["+91 9405480101", "+91 9765723830"]
    },
    {
      icon: FaClock,
      title: "Business Hours",
      content: [
        "Tuesday - Sunday: 9 am–8 pm",
        "Monday: Closed"
      ]
    }
  ];

  return (
    <Container id="contact" maxW="container.xl" py={20}>
      <VStack spacing={10} align="stretch">
        <Heading as="h2" size="2xl" color="gray.800" textAlign="center">Contact Us</Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
          {contactDetails.map((detail, index) => (
            <Box key={index} bg="white" p={8} borderRadius="lg" boxShadow="xl" textAlign="center">
              <Icon as={detail.icon} boxSize={10} color="brand.500" mb={4} />
              <Heading as="h3" size="lg" mb={4} color="gray.700">{detail.title}</Heading>
              {Array.isArray(detail.content) ? (
                detail.content.map((item, idx) => (
                  <Text key={idx} color="gray.600">{item}</Text>
                ))
              ) : (
                <Text color="gray.600">{detail.content}</Text>
              )}
            </Box>
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  );
};

const useHeaderHeight = () => {
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    const updateHeaderHeight = () => {
      const header = document.querySelector('header');
      if (header) {
        setHeaderHeight(header.offsetHeight);
      }
    };

    updateHeaderHeight();
    window.addEventListener('resize', updateHeaderHeight);

    return () => window.removeEventListener('resize', updateHeaderHeight);
  }, []);

  return headerHeight;
};

const LandingPage = () => {
  const headerHeight = useHeaderHeight();
  const products = [
    {
      name: "Stitcher Cum Gluer",
      description: "Our versatile Stitcher Cum Gluer machine offers a comprehensive solution for both stitching and gluing operations. This innovative equipment streamlines your production process by combining two essential functions into one efficient unit, saving you time and space on your factory floor.",
      videoUrl: "https://www.youtube.com/embed/JWxFp5ZjbAE",
      icon: FaIndustry,
      technicalDetails: {
        "Stitching Speed": "400 stitches / min",
        "No. of Stitches": "3-99 stitches (adjustable)",
        "Stitches Pitch": "20-75min (adjustable)",
        "Power required": "3½ hp",
        "M/C weight": "2000kg",
        "Additional Features": [
          "Gluing tool can be attached",
          "Operator friendly touch screen monitor for easy changing of parameters",
          "Alarm on finishing of pin wire",
          "Batch and daily production counter",
          "Machine can stitch single and double piece box",
          "Maintenance free rubber rolls",
          "Eliminates skilled worker",
          "Independent control for front and rear feeding wheels offer auto feed and push in control types to fit different products",
          "Double linkage stitching head made by special alloy steel with precise grinding requiring no maintenance and easy to operate",
          "All operations controlled by programmable logic controller"
        ],
      },
    },
    {
      name: "Double Joint Box Stitching Machine",
      description: "The Semi-Automatic Double Joint Box Stitching Machine (With 4 SERVOS) introduces high speed stitched boxes with high accuracy & better quality. This advanced machine ensures consistent, high-quality stitching for various box sizes, making it an ideal choice for businesses requiring robust packaging solutions.",
      videoUrl: "https://www.youtube.com/embed/WrfyRuvy4Pk",
      icon: FaCog,
      technicalDetails: {
        "Stitching Speed": "400 stitches / min",
        "No. of Stitches": "3-99 stitches (adjustable)",
        "Stitches Pitch": "20-75min (adjustable)",
        "Power required": "5.5 hp",
        "MAX (Length + Width)X 2": "1600mm x 2",
        "MIN (Length + Width)X 2": "460mm x 2",
        "M/C weight": "2000kg",
        "Additional Features": [
          "4 servos with 6g euro technology",
          "Double joint box stitcher",
          "All operations controlled by programmable logic controller",
          "Batch and daily production counter",
          "Operator friendly touch screen monitor for easy change of parameters",
          "Machine can do double joint and single joint boxes",
          "Machine can do double, single and strengthing nails",
          "Machine has auto wire feeder for bottom side nailing",
          "Eliminates skilled worker"
        ],
      },
    },
    {
      name: "Semi Auto Flap Pasting Machine",
      description: "Our Semi Auto Flap Pasting Machine offers a perfect balance between automation and manual control. This equipment significantly increases productivity in flap pasting operations while allowing operators to maintain oversight of the process, ensuring quality and flexibility in your box manufacturing.",
      videoUrl: "https://www.youtube.com/embed/_zidbtAWulQ",
      icon: FaTools,
      technicalDetails: {
        "Total Power": "2.0 HP",
        "Speed": "2200 box / hour",
        "Max sheet (A + B) X2": "2600mm x 2",
        "Min sheet (A + B) X2": "450mm x 2",
        "Max sheet (Height)": "1000mm",
        "Min sheet (Height)": "200mm",
        "Glue Reservoir Capacity": "4 kg",
        "Additional Features": [
          "Auto rising for feed table",
          "Auto settable batch counter",
          "Auto squaring and pressing",
          "Gluing done by rotary roller applicator",
          "Specially designed glue head with steel glue applicator",
          "Glue reservoir for 4 kg capacity"
        ],
      },
    },
    {
      name: "Automatic Box Stitching Machine",
      description: "The Automatic Box Stitching Machine represents the pinnacle of efficiency in high-volume box production. This fully automated system delivers consistent, high-quality stitching at impressive speeds, dramatically increasing your output while maintaining precision and reliability.",
      videoUrl: "https://www.youtube.com/embed/rV3VXIcMK_A",
      icon: FaIndustry,
      technicalDetails: {
        "Stitching Speed": "400 stitches / min",
        "No. of Stitches": "3-99 stitches (adjustable)",
        "Stitches Pitch": "20-75min (adjustable)",
        "Power required": "5.5 hp",
        "M/C weight": "2500kg",
        "Additional Features": [
          "Gluing tool can be attached",
          "Operator friendly touch screen monitor for easy changing of parameters",
          "Alarm on finishing of pin wire",
          "Batch and daily production counter",
          "Machine can stitch single and double piece box",
          "Maintenance free rubber rolls",
          "Eliminates worker",
          "Independent control for front and rear feeding wheels offer auto feed and push in control types to fit different products",
          "Double linkage stitching head made by special alloy steel with precise grinding requiring no maintenance and easy to operate",
          "All operations controlled by programmable logic controller"
        ],
      },
    },
    {
      name: "Servo Controlled Manual Box Stitcher",
      description: "Our Servo Controlled Manual Box Stitcher combines precision servo technology with manual operation flexibility. This machine offers exact control over the stitching process, providing unparalleled accuracy and adjustability for a wide range of box types and sizes.",
      videoUrl: "https://www.youtube.com/embed/hsj2Z2w_AI4",
      icon: FaCog,
      technicalDetails: {
        "Stitching Speed": "400 stitches / min",
        "No. of Stitches": "3-99 stitches (adjustable)",
        "Stitches Pitch": "20-75min (adjustable)",
        "Power required": "2½ hp",
        "M/C weight": "400kg",
        "Additional Features": [
          "Operator friendly touch screen monitor for easy change of parameters",
          "Machine can do double joint and single joint boxes",
          "Machine can do double, single and strengthing nails",
          "Eliminates skilled worker",
          "Sizes available 36\", 42\", 48\""
        ],
      },
    },
  ];

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset - headerHeight - 16; // Added a small offset
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <Box minHeight="100vh" display="flex" flexDirection="column">
        <Box as="header" bg="white" boxShadow="lg" position="sticky" top={0} zIndex={10}>
          <Container maxW="container.xl" py={4}>
            <Flex justify="space-between" align="center" direction={{ base: "column", md: "row" }}>
              <Heading as="h1" size="xl" color="brand.500" mb={{ base: 4, md: 0 }}>MEPL</Heading>
              <Flex as="nav" gap={{ base: 2, md: 8 }} wrap="wrap" justify="center">
                {['About', 'Products', 'Contact'].map((item) => (
                  <Button 
                    key={item} 
                    variant="ghost" 
                    onClick={() => scrollToSection(item.toLowerCase())}
                    color="gray.700" 
                    _hover={{ color: 'brand.500' }}
                    fontSize={{ base: "sm", md: "md" }}
                    px={{ base: 2, md: 4 }}
                  >
                    {item}
                  </Button>
                ))}
              </Flex>
            </Flex>
          </Container>
        </Box>

        <VStack as="main" spacing={0} flex={1}>
          <Box w="full" py={20} bg="brand.500" color="white">
            <Container maxW="container.xl" textAlign="center">
              <Heading as="h2" size="4xl" mb={8} fontWeight="bold">Pioneers in Box Stitching Machines</Heading>
              <Text fontSize="2xl" mb={12} maxW="3xl" mx="auto">Innovative solutions for the packaging industry, delivering precision and efficiency to elevate your production capabilities.</Text>
              <Button size="lg" bg="white" color="brand.500" _hover={{ bg: 'gray.100' }} onClick={() => scrollToSection('products')} fontWeight="bold">
                Explore Our Products
              </Button>
              <Flex justify="center" mt={12}>
                <Flex
                  align="center"
                  bg="white"
                  color="brand.500"
                  px={6}
                  py={3}
                  borderRadius="full"
                  boxShadow="lg"
                  fontWeight="bold"
                >
                  <Icon as={FaFlag} mr={3} />
                  Made in India
                </Flex>
              </Flex>
            </Container>
          </Box>

          <Container id="about" maxW="container.xl" py={16} bg="white">
            <VStack spacing={10}>
              <Heading as="h2" size="2xl" color="gray.800">About MEPL</Heading>
              <Text fontSize="lg" textAlign="center" maxW="3xl" color="gray.600">
                At MEPL, we are dedicated to developing cutting-edge box stitching and pasting machines. 
                Our commitment to innovation and quality has established us as pioneers in the industry, 
                providing advanced solutions for packaging manufacturers across India and beyond.
              </Text>
              <AspectRatio ratio={16 / 9} width="100%" maxW="4xl" boxShadow="xl" borderRadius="lg" overflow="hidden">
                <iframe
                  src="https://www.youtube.com/embed/9gQp5-rMzqg"
                  title="MEPL Company Profile"
                  allowFullScreen
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              </AspectRatio>
            </VStack>
          </Container>

          <Box id="products" w="full" bg="gray.200" py={16}>
            <Container maxW="container.xl">
              <VStack spacing={16} align="stretch">
                <Heading as="h2" size="2xl" color="gray.800" textAlign="center" mb={10}>
                  Our Product Range
                </Heading>
                {products.map((product, index) => (
                  <ProductSection key={index} {...product} isReversed={index % 2 !== 0} />
                ))}
              </VStack>
            </Container>
          </Box>

          <Box bg="white">
            <ContactSection />
          </Box>
        </VStack>

        <Box as="footer" bg="gray.900" color="white" py={12}>
          <Container maxW="container.xl">
            <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
              <Text fontSize="md">© 2024 MEPL. All rights reserved.</Text>
              <Flex as="nav" gap={8} mt={{ base: 6, md: 0 }}>
                <Button variant="link" color="white" size="md" _hover={{ color: 'brand.300' }}>Terms of Service</Button>
                <Button variant="link" color="white" size="md" _hover={{ color: 'brand.300' }}>Privacy Policy</Button>
              </Flex>
            </Flex>
          </Container>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default LandingPage;
